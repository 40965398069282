@import './sass/observer-panel/observer-variables';
@import './sass/observer-panel/mixins/icon-outline';
@import './sass/observer-panel/mixins/buttons';

@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/_mixins';

body {
    margin: 0;
    padding: 0;
    position: relative;
    overflow-x: hidden;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif !important;
    background: $backgroundColor;
    color: $textColor;
}

body,
html {
    height: 100%;
}

@for $i from 1 through 6 {
    // Apply styling to h1 through h6
    h#{$i},
    .h#{$i} {
        font-family: 'Belfius';
    }
}

/*-------------------------------*/
/*           Wrappers            */
/*-------------------------------*/
#wrapper {
    @include media-breakpoint-up(lg) {
        display: flex;
    }

    @media (min-height: 512px) {
        height: 100%;
    }
}

#sidebar-wrapper {
    display: flex;
    flex-direction: column;
    width: 300px;
    background: $sidebarBackgroundColor;
    font-family: 'Belfius';

    @include media-breakpoint-up(lg) {
        flex-grow: 1;
    }

    @include media-breakpoint-down(md) {
        position: fixed;
        left: -300px;
        z-index: 1000;
        height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;
        transition: all 0.5s ease;
        transition: all 0.5s ease;
    }
}

#sidebar-wrapper::scrollbar {
    display: none;
}

#wrapper.toggled #sidebar-wrapper {
    left: 0;
    transition-delay: 0.1s;
}

#page-content-wrapper {
    position: relative;
    width: 100%;
    overflow-y: auto;
    padding-bottom: 3rem;
}

/*-------------------------------*/
/*     Sidebar nav styles        */
/*-------------------------------*/

.sidebar-nav {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: $sidebarBackgroundColor;

    @include media-breakpoint-down(md) {
        position: absolute;
        top: 0;
    }

    li {
        position: relative;
        width: 100%;

        a {
            display: inline-flex;
            width: 100%;
            padding: 0.5rem 0.75rem.5rem 1.5rem;
            line-height: 2.25rem;
            color: $sidebarTextColor;
            text-decoration: none !important;
            transition: all 0.2s ease-in-out;

            .icon-outline {
                margin-left: (0.75rem / 2);
                margin-right: (0.75rem + (0.75rem / 2));
                transition: border-color 0.2s ease-in-out;
            }

            &.language {
                width: auto;
            }

            &:not([href]):not([tabindex]) {
                color: $sidebarTextColor;
            }

            &:hover {
                cursor: pointer;

                .user-initial {
                    background-color: darken($red, 2.5%);
                }

                .icon-outline {
                    border-color: $sidebarTextColorHover;
                }
            }
        }

        a:hover,
        a:active,
        a:focus,
        &.open a:hover,
        &.open a:active,
        &.open a:focus {
            color: $sidebarTextColorHover;
            text-decoration: none;
            background-color: transparent;
        }
    }

    & > .sidebar-brand {
        display: inline-flex;
        padding: 2rem 1rem;

        a {
            width: auto;
            padding: 0;
        }
    }

    .white-space {
        flex-grow: 3;
    }
}

.sidebar-nav-options {
    margin-bottom: 1rem;
}

.sidebar-nav-options-dropdown {
    width: calc(100% - 1rem);
    margin-left: 0.5rem;
    background: $sidebarBackgroundColorDark;

    li a {
        padding: 0.5rem 0.75rem 0.5rem 1.5rem;
        line-height: 2.25rem;
        color: $sidebarTextColor;

        &:hover,
        &:active,
        &:focus {
            color: $sidebarTextColorHover !important;
            text-decoration: none;
            background-color: transparent;
        }
    }
}

.sidebar-nav-options-toggle {
    align-items: center;

    &:after {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: '';
        border-bottom: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-top: 0;
        border-left: 0.3em solid transparent;
    }
}

/*-------------------------------*/
/*       Hamburger         */
/*-------------------------------*/
.sidebar-toggle {
    margin-right: 1rem;
    padding: 0;
    color: $white;
    background: transparent;

    @include icon-outline(2.5rem, 0.1rem, $sidebarTextColor, $sidebarTextColorHover);

    @include media-breakpoint-up(lg) {
        display: none;
    }

    &:focus {
        outline: none;
    }
}

/*-------------------------------*/
/*            Overlay            */
/*-------------------------------*/

@include media-breakpoint-down(lg) {
    #wrapper.toggled .overlay {
        visibility: visible;
        z-index: 1;
        background-color: rgba(250, 250, 250, 0.75);
    }
}

.overlay {
    position: fixed;
    visibility: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(250, 250, 250, 0);
    z-index: -100;
    transition: visibility 0s, background-color 0.2s ease;

    @include media-breakpoint-up(lg) {
        display: none;
    }

    &:hover {
        cursor: pointer;
    }
}

/*-------------------------------*/
/*            Content            */
/*-------------------------------*/

.logo {
    display: inline-block;
    vertical-align: middle;
    width: 134px;
    height: 34px;
    background: url('/assets/images/buck-e-logo.svg') top left no-repeat;
    background-size: 134px 34px;
}

.icon-outline {
    $size: 2.25rem;

    @include icon-outline($size, 0.1rem, $sidebarTextColor, $sidebarTextColorHover);
}

li.user {
    $size: 3rem;

    a {
        color: $white;
        line-height: 3rem;

        &.active .user-name {
            position: relative;

            &:after {
                position: absolute;
                left: 0;
                bottom: 0.25rem;
                content: '';
                width: 100%;
                height: 0.1rem;
                border-bottom: 0.1rem solid rgba(255, 255, 255, 0.25);
            }
        }
    }

    .user-initial {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        height: $size;
        width: $size;
        line-height: $size;
        background: $red;
        color: $white;
        text-align: center;
        transition: background-color 0.2s ease-in-out;
    }

    .user-name {
        flex-grow: 1;
        margin-left: 0.75rem;
        line-height: $size;
    }
}

.close-sidebar {
    @include media-breakpoint-up(lg) {
        display: none;
    }

    &:hover {
        cursor: pointer;
    }
}

.user-options {
    ul {
        display: flex;

        li {
            flex-grow: 1;
            list-style: none;

            &:not(:first-child) {
                &:before {
                    position: absolute;
                    top: 25%;
                    left: 0;
                    content: '';
                    border-left: 1px solid $sidebarTextColor;
                    height: 50%;
                }
            }
        }
    }
}

/*-------------------------------*/
/*            Backdrop           */
/*-------------------------------*/

.backdrop {
    $height: ($backdropWidth / $backDropdefaultWidth) * $backDropdefaultHeight;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
    height: $height;
}

.backdrop-title {
    display: flex;
    margin: 6rem 0;
    color: $white;

    .backdrop-title-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 0.75rem;
    }

    .breadcrumb {
        margin-top: 0.5rem;
        font-size: 50%;
        font-family: 'Open Sans';

        a {
            color: $white;
            text-decoration: none;
        }
    }
}

.city-backdrop {
    $height: ($backdropWidth / $backDropdefaultWidth) * $backDropdefaultHeight;
    padding: 3rem 1.5rem;
    background-color: $redLight;
    background-image: url('/assets/images/city-background-white-30.svg');
    background-size: $backdropWidth $height;
    background-position: top center;
    background-repeat: no-repeat;
    font-family: 'Belfius';

    @include media-breakpoint-up(sm) {
        padding: 3rem;
    }

    &.backdrop-small .backdrop-title {
        margin: 0;
    }

    &.backdrop-block {
        margin-bottom: 2rem;
    }

    @for $i from 1 through 6 {
        // Apply styling to h1 through h6
        h#{$i},
        .h#{$i} {
            color: $white;
        }
    }

    @include media-breakpoint-only(xs) {
        .container {
            padding: 0;
        }
    }
}

/*-------------------------------*/
/*         Page Content          */
/*-------------------------------*/

.card {
    margin-bottom: 2rem;

    .card-body {
        padding: 1.5rem 3rem;
    }
}

.list-group-flush {
    .list-group-item {
        padding: $list-group-item-padding-y 0;

        &:first-child {
            border-top: $list-group-border-width solid $list-group-border-color !important;
        }

        &:last-child {
            border-bottom: $list-group-border-width solid $list-group-border-color !important;
        }
    }
}

hr {
    margin: 2rem 0;
    border-top: 1px solid rgba(137, 155, 168, 0.3);
}

.button-block {
    width: 100%;
}

.button {
    display: inline-flex;
    @include button($redLight, $red);
    text-decoration: none;
}

.terms a {
    color: $textColorLight;
    text-decoration: none;

    &:first-child::after {
        display: inline-block;
        content: '|';
        margin: 0 0.5rem;
    }
}
