/* Used Transfonter (https://transfonter.org/) */
@font-face {
    font-family: 'Open Sans';
    src: url('/assets/fonts/OpenSans-Regular.woff2') format('woff2'), url('/assets/fonts/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/assets/fonts/OpenSans-Italic.woff2') format('woff2'), url('/assets/fonts/OpenSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/assets/fonts/OpenSans-Bold.woff2') format('woff2'), url('/assets/fonts/OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-weight: 700;
}
