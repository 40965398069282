@import './observer-variables';
@import './mixins/icon-outline';

.faq-popover {
    @include icon-outline(1.75rem, 0.1rem, $textColor, $white);
    font-weight: bold;
    background-color: $textColor;

    &:focus {
        outline: none;
    }
}
