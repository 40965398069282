$borderRadius: 0.75rem;

$white: #fff;
$black: #000;
$redLight: #f03241;
$red: #c3204b;

$sidebarBackgroundColor: #7e0038;
$sidebarBackgroundColorDark: #60002b;
$sidebarTextColor: rgba(255, 255, 255, 0.5);
$sidebarTextColorHover: rgba(255, 255, 255, 0.7);

$backDropdefaultWidth: 375px;
$backDropdefaultHeight: 213px;

$balanceCardBackgroundColor: #8b193c;

$backdropWidth: 768px;

//
// NGX Datepicker
//

$main-bg: #fff !default;
$custom-range-bg: #eee !default;

$main-box-shadow: #aaa !default;

$font-color-01: #fff !default;
$font-color-02: #9aaec1 !default;
$font-color-03: #54708b !default;

$border-color: #e9edf0 !default;
$highlighted: #e9edf0 !default;

$btn-bg: #e9edf0 !default;
$btn-bg-hover: #d5dadd !default;

$btn-bg2: #9aaec1 !default;
$btn-bg2-hover: #54708b !default;

$theme-red: $red !default;

$disabled-background: rgba(221, 221, 221, 0.3) !default;
$disabled-color: #f5f5f5 !default;

$theme-list: (
    'default': $theme-red,
    // 'green': $theme-green,
    // 'blue': $theme-blue,
    // 'dark-blue': $theme-dark-blue,
    // 'red': $theme-red,
    // 'orange': $theme-orange,,,
) !default;

//
// Bootstrap
//

$textColor: #51626f;
$textColorLight: #899ba8;
$backgroundColor: #fafafa;
$danger: #e7803f !default;

// $primary:       $blue !default;
$secondary: #d2d2d7 !default;
// $success:       $green !default;
// $info:          $cyan !default;
// $warning:       $yellow !default;
// $danger:        $red !default;
// $light:         $gray-100 !default;
// $dark:          $gray-800 !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
    (
        // "primary":    $primary,
        'secondary': $secondary,
        // "success":    $success,
        // "info":       $info,
        // "warning":    $warning,
        // "danger":     $danger,
        // "light":      $light,
        // "dark":       $dark
    ),
    $theme-colors
);

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$h1-font-size: $font-size-base * 2.25 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

// Components
//
// Define common padding and border radius sizes and more.

$border-radius: 0.25rem !default;
$border-radius-lg: 0.3rem !default;
$border-radius-sm: 0.2rem !default;

// Cards
$padding-x: 3rem;
$padding-y: 2rem;

$card-bg: $white;
$card-border-radius: $borderRadius;

// Badges

$badge-font-size: 75% !default;
$badge-font-weight: bold !default;
$badge-color: $white !default;
$badge-padding-y: 0.25em !default;
$badge-padding-x: 0.5em !default;
$badge-border-radius: $border-radius !default;

// Breadcrumbs

$breadcrumb-padding-y: 0 !default;
$breadcrumb-padding-x: 0 !default;
$breadcrumb-item-padding-x: 0.5rem !default;

$breadcrumb-margin-bottom: 0 !default;

$breadcrumb-bg: transparent !default;
$breadcrumb-divider-color: $white !default;
$breadcrumb-active-color: $white !default;
$breadcrumb-divider: quote('>') !default;

$breadcrumb-border-radius: 0 !default;

// List group

$list-group-border-color: rgba(137, 155, 168, 0.3);

// Popovers

$popover-bg: $white !default;
$popover-border-width: 1px !default;
$popover-border-color: $white !default;
$popover-border-radius: $border-radius-lg !default;
$popover-box-shadow: 0 0.25rem 0.5rem rgba($black, 0.2) !default;

$popover-header-bg: $white !default;
$popover-header-color: $textColor !default;
$popover-header-padding-y: 0.5rem !default;
$popover-header-padding-x: 0.75rem !default;

$popover-body-color: $textColor !default;

$popover-arrow-width: 2rem !default;
$popover-arrow-height: 1rem !default;
$popover-arrow-color: $popover-bg !default;

$popover-arrow-outer-color: fade-in($popover-border-color, 0.05) !default;

// Modal
$modal-content-bg: transparent;
$modal-content-border-width: 0px;

//
// Animations
//
$animationTime: 0.3s;
