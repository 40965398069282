@mixin button(
    $background,
    $border,
    $hover-background: darken($background, 7.5%),
    $hover-border: darken($border, 10%),
    $active-background: darken($background, 10%),
    $active-border: darken($border, 12.5%)
) {
    padding: 0.5rem 1.5rem;
    background-color: $background;
    font-size: 1rem;
    border-radius: 0.25rem;
    color: #fff;
    border: none;

    &:hover {
        cursor: pointer;
        color: #fff;
        text-decoration: none;
    }

    &:focus {
        outline: none;
    }

    &:disabled {
        background-color: #d2d2d7;

        &:hover {
            cursor: not-allowed;
        }
    }
}
