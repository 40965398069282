@import 'progress-tracker-variables.scss';
@import 'progress-tracker-mixins.scss';
@import 'progress-tracker-animations.scss';

// Borrowed from https://github.com/NigelOToole/progress-tracker/

// ----- Progress Tracker -----

// ----- Elements -----

// Container element
.progress-tracker {
    display: flex;
    margin: 0 auto;
    list-style: none;
}

// Step container that creates lines between steps
.progress-step {
    display: block;
    position: relative;
    flex: 1 1 0%;
    margin: 0;
    padding: 0;
    min-width: $marker-size; // For a flexbox bug in firefox that wont allow the text overflow on the text

    // Stops the last step growing
    &:last-child {
        flex-grow: 0;
    }

    // Path between markers, this is not created for the last step
    &:not(:last-child)::after {
        content: '';
        display: block;
        position: absolute;
        z-index: -10;
        top: $path-position;
        bottom: $path-position;
        right: -$marker-size-half;
        width: 100%;
        height: $path-height;
        transition: background-color $animation-duration;
    }

    // Active state
    &.is-active {
        .progress-title {
            font-weight: 400;
        }
    }

    > a {
        display: block;
    }
}

// Progress marker
.progress-marker {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 20;
    width: $marker-size;
    height: $marker-size;
    padding-bottom: 2px; // To align text within the marker
    color: #fff;
    font-weight: 400;
    border: 2px solid transparent;
    border-radius: 50%;
    transition: background-color, border-color;
    transition-duration: $animation-duration;
}

// Progress text
.progress-text {
    display: block;
    padding: $text-padding-Y $text-padding-X;
    overflow: hidden;
    text-overflow: ellipsis;
}
.progress-title {
    margin-top: 0;
}

// States
.progress-step {
    // Inactive - Default state
    @include progress-state($progress-color-grey-light, null, #fff, $progress-color-grey-light, $progress-color-grey-dark);

    // Active state
    &.is-active {
        @include progress-state($progress-color);
    }

    // Complete state
    &.is-complete {
        @include progress-state($progress-color-dark, $path-color: $progress-color-grey);
    }

    // Hover state
    &:hover {
        @include progress-state($progress-color-light);
    }
}

// ----- Modifiers -----

// Center align markers and text
.progress-tracker--center {
    .progress-step {
        text-align: center;

        &:last-child {
            flex-grow: 1;
        }

        &::after {
            right: -50%;
        }
    }

    .progress-marker {
        margin-left: auto;
        margin-right: auto;
    }
}

// Right align markers and text
.progress-tracker--right {
    .progress-step {
        text-align: right;

        &:last-child {
            flex-grow: 1;
        }

        &::after {
            right: calc(-100% + #{$marker-size-half});
        }
    }

    .progress-marker {
        margin-left: auto;
    }
}

// Border around steps (Only for use without text)
.progress-tracker--border {
    padding: $progress-tracker-padding;
    border: 2px solid $progress-color-grey;
    border-radius: $marker-size + ($progress-tracker-padding * 2);
}

// Spaces between markers
.progress-tracker--spaced {
    .progress-step {
        &::after {
            width: calc(100% - #{$marker-size + ($marker-spacing * 2)});
            margin-right: ($marker-size-half + $marker-spacing);
        }
    }
}

// Word below markers
.progress-tracker--word {
    padding-right: $progress-tracker-word-padding;
    overflow: hidden;

    .progress-text {
        display: inline-block;
        white-space: nowrap;
    }

    .progress-title {
        margin: 0;
    }
}

.progress-tracker--word-center {
    padding-right: $progress-tracker-word-padding;
    padding-left: $progress-tracker-word-padding;

    .progress-text {
        padding-right: 0;
        padding-left: 0;
        transform: translateX(calc(-50% + #{$marker-size-half}));
    }
}

.progress-tracker--word-right {
    padding-right: 0;
    padding-left: $progress-tracker-word-padding;

    .progress-text {
        padding-left: 0;
        transform: translateX(calc(-100% + #{$marker-size}));
    }
}

// Text below markers
.progress-tracker--text {
    .progress-step {
        &:last-child {
            flex-grow: 1;
        }
    }
}

// Text above markers
.progress-tracker--text-top {
    .progress-step::after {
        top: auto;
    }

    .progress-text {
        height: 100%;
    }

    .progress-marker {
        bottom: $marker-size;
    }
}

// Text inline with markers
.progress-tracker--text-inline {
    .progress-step {
        display: flex;
    }

    .progress-text {
        position: relative;
        z-index: 30;
        max-width: 70%;
        white-space: nowrap;
        padding-top: 0;
        padding-bottom: 0;
        background-color: #fff;
    }

    .progress-title {
        margin: 0;
    }
}

// Square markers
.progress-tracker--square {
    .progress-step {
        padding-top: 0;
    }

    .progress-marker {
        transform: scaleX(0.33) translateY(-$path-position);
        border-radius: 0;
    }
}

// Overflow on small screens
@media (max-width: 399px) {
    .progress-tracker-mobile {
        overflow-x: auto;

        .progress-tracker {
            min-width: 200%;
        }
    }
}

// Vertical layout
.progress-tracker--vertical {
    flex-direction: column;

    .progress-step {
        flex: 1 1 auto;

        &::after {
            right: auto;
            top: $marker-size-half;
            left: $path-position;
            width: $path-height;
            height: 100%;
        }
    }

    .progress-marker {
        position: absolute;
        left: 0;
    }

    .progress-text {
        padding-top: $marker-size-quarter;
        padding-left: $text-padding--vertical;
    }

    .progress-step:not(:last-child) .progress-text {
        padding-bottom: $text-padding--vertical;
    }
}
