@import '../observer-variables';
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/_mixins';
@import '~bootstrap/scss/_reboot';
@import './utilities';
@import '~bootstrap/scss/_close';
@import '~bootstrap/scss/_type';
@import '~bootstrap/scss/_breadcrumb';
@import '~bootstrap/scss/_list-group';
@import '~bootstrap/scss/_alert';
@import '~bootstrap/scss/_tooltip';
@import '~bootstrap/scss/_dropdown';

// Override variables
$card-spacer-y: $padding-y / 2;
$card-spacer-x: $padding-x / 2;

// Custom Bootstrap
@import './badge';
@import './buttons';
@import './card';
@import './dropdown';
@import './forms';
@import './modal';
@import './popover';

// NGX Bootstrap
@import './datepicker';

// Other
@import '../crooked-card';
@import '../faq-tooltip';
@import '../faq-popover';

/* Form control */
.form-control:focus {
    box-shadow: 0 0 0 0.1rem rgba($redLight, 0.25);
}

/* Dropdown fix */
.dropdown-toggle {
    &:focus {
        box-shadow: 0 0 0 0.2rem rgba($red, 0.25);
    }
}

.btn-group {
    position: relative;

    &.open {
        .dropdown-menu {
            display: block;
        }
    }
}

/* Animations */
.collapse {
    $animation: ease-in-out;

    /* setup transition however you like */
    -webkit-transition: max-height $animationTime $animation;
    -moz-transition: max-height $animationTime $animation;
    -o-transition: max-height $animationTime $animation;
    -ms-transition: max-height $animationTime $animation;
    transition: max-height $animationTime $animation;

    /* override display, overflow and visibility */
    display: block !important;
    overflow: hidden !important;
    visibility: visible !important;
    max-height: 0;

    &.in {
        /* set max-height to something high so that
    it can fit whatever you have inside your collapsible tag.
    Not too high though, because the animation time will be based
    on this value, so if it's too high the animation will be
    very fast */
        max-height: 1000px;
    }
}
