@import './observer-variables';

@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/_mixins';

.crooked-card {
    margin-top: 3rem;
    border-radius: $borderRadius;
    background: #fff;
    box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.08);

    h1 {
        position: absolute;
        margin: 0 0 0 $padding-x;
        z-index: 2;
        line-height: 6.5rem;
        font-size: 1.75rem;
        color: #fff;
    }

    .crooked-card-header {
        height: 7.5rem;
        position: relative;
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;
        overflow-x: hidden;
        overflow-y: visible;

        .crooked-card-bg {
            position: absolute;
            top: -1.5rem;
            left: -1.5rem;
            width: 110%;
            height: 7.5rem;
            transform: rotate(-3.5deg);
            background: $red;
            background: linear-gradient(90deg, $red 0%, $redLight 100%);
            z-index: 1;
        }
    }

    .crooked-card-body {
        padding: ($padding-y / 2) ($padding-x / 2);

        @include media-breakpoint-up(sm) {
            padding: $padding-y $padding-x;
        }
    }

    form {
        .form-group {
            &:not(.form-check) {
                label {
                    display: block;
                    margin-bottom: 0.5rem;
                    font-weight: bold;
                }
            }

            input:not([type='checkbox']):not([type='radio']),
            select {
                flex-grow: 1;
                padding: 0.25rem 0.5rem;
                height: 2.2rem;
                margin-right: 0.75rem;
                border-radius: 0.25rem;
                border: 2px solid $secondary;
            }
        }

        .button {
            height: 2.5rem;
        }
    }
}
