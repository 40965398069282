@import '~bootstrap/scss/bootstrap-grid';

// ngx-bootstrap
// NOTE: We only use the modules but have our own custom css!
@import '~ngx-bootstrap/datepicker/bs-datepicker';
@import './ngx-bootstrap/ngx-bootstrap';

@import './progress-tracker/progress-tracker.scss';

// Fonts
@import '../fonts/belfius';
@import '../fonts/open-sans';

.form-row.can-have-error {
    .form-group {
        margin-bottom: 0;
    }
}

.form-row-error {
    margin-bottom: 1rem;
}

select {
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    padding: 0.25rem 3rem 0.25rem 0.5rem !important;
    -webkit-padding-end: 3rem !important;

    background-image: linear-gradient(45deg, transparent 50%, $white 50%), linear-gradient(135deg, white 50%, transparent 50%),
        linear-gradient(to right, $redLight, $redLight);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    background-repeat: no-repeat;

    &:focus {
        background-image: linear-gradient(45deg, $white 50%, transparent 50%), linear-gradient(135deg, transparent 50%, $white 50%),
            linear-gradient(to right, $red, $red);
        background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, 100% 0;
        background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
        background-repeat: no-repeat;
        outline: 0;
    }
}

.messages {
    z-index: 10000;
}

.popover {
    padding: 1rem;
    box-shadow: 0px 2px 28px 0px rgba(0, 0, 0, 0.16);
}

.scans-popover {
    border-radius: 0.75rem;

    &.bottom > .arrow {
        margin-left: 3px !important;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .popover-header {
        font-size: 1.25rem;
        min-width: 250px;
        font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
        font-weight: bold;
        border-bottom: none;
    }

    li {
        display: flex;

        &:not(:last-child) {
            margin-bottom: 1rem;
        }

        .scan-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
        }

        .scan-type {
            margin-left: 0.25rem;
            font-size: 1rem;
            color: $textColorLight;
        }
    }

    .scanned {
        display: inline-block;
        width: 22px;
        height: 22px;
        background-color: $redLight;
        border-radius: 50%;
    }

    .yet-to-scan {
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-top: 2px;
        border: 3px solid #899ba8;
        border-radius: 50%;
    }
}
