// Step state mixin - The first arugment is required and the rest are optional, if you pass in null the value will not be changed.
@mixin progress-state($marker-color-bg, $marker-color-border: null, $marker-color-text: null, $path-color: null, $text-color: null) {
    .progress-marker {
        color: $marker-color-text;
        background-color: $marker-color-bg;
        border-color: $marker-color-border;
    }

    &::after {
        background-color: $path-color;
    }

    .progress-text,
    .progress-step > a .progress-text {
        color: $text-color;
    }
}

// Ripple elements mixin
@mixin ripple-element() {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 30;
    background: $ripple-color;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0); // Initial state, should hide the effect when not animating
    visibility: hidden; // Hides element so it doesnt animate on page load
}
