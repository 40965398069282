@mixin icon-outline($size: 2.25rem, $borderWidth: 0.1rem, $borderColor: $sidebarTextColor, $iconColor: $sidebarTextColorHover) {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 0.1rem solid $borderColor;
    height: $size;
    width: $size;
    max-width: $size;
    text-align: center;

    span,
    .material-icons {
        color: $iconColor;
        line-height: inherit;
    }
}
