// ----- Variables -----

// Colours
$progress-color-dark: #f03241 !default;
$progress-color: #f03241 !default;
$progress-color-light: #f03241 !default;
$progress-color-grey-light: #899ba8 !default;
$progress-color-grey: rgba(137, 155, 168, 0.3) !default;
$progress-color-grey-dark: rgba(137, 155, 168, 0.3) !default;

// Sizing
$progress-tracker-padding: 5px !default;

$marker-size: 22px !default;
$marker-size-half: ($marker-size / 2);
$marker-size-third: ($marker-size / 3);
$marker-size-quarter: ($marker-size / 4);
$marker-spacing: 10px !default;

$path-height: 4px !default;
$path-position: $marker-size-half - ($path-height / 2);

$text-padding: $marker-size-half !default;
$text-padding-X: $marker-size-third !default;
$text-padding-Y: $marker-size-half !default;
$text-padding--vertical: $marker-size + $marker-size-half !default;

// Only needed for short text version, the word size should be the width of the widest word without padding.
$word-size: 54px !default;
$progress-tracker-word-padding: ($word-size + $text-padding-X + $marker-size-half) / 2;

// Animations/Transitions
$animation-duration: 0.3s !default;
$ripple-color: rgba(0, 0, 0, 0.3) !default;
